<template>
    <div class="fixed z-10 inset-0 overflow-y-auto bg-white bg-opacity-50">
        <div class="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0"></div>
            </div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>

            <div class="inline-block border border-gray-400 align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full px-2" role="dialog" aria-modal="true">
                <p class="text-xl font-semibold pt-2" style="color: #006787">{{ $t('yourQuotation') }}</p>
                <div class="text-center pb-5">
                    <p class="text-sm text-gray-800">{{ $t('fillInYourZipCodeToViewTheQuotation') }}</p>
                </div>
                <label class="pr-4" for="zipCode">{{ $t('zipCode') }}</label>
                <input
                    autocomplete="off"
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    class="border border-gray-500 mb-5"
                    v-model="zipCode"
                    placeholder="1234AB"
                    @keyup.enter="retrieveQuotation"
                >
                <div class="w-full text-center pb-3 text-red-500">{{ error }}</div>
                <button @click="retrieveQuotation" type="button" class="w-5/6 md:w-3/6 rounded-full border border-gray-300 shadow-sm py-2 mb-3 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
                    <div class="mx-auto items-center justify-center w-3/6 rounded-full">
                        {{ $t('retrieveQuotation') }}
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    data () {
        return {
            zipCode: '',
            error: ''
        }
    },
    methods: {
        ...mapActions('quotation', [
            'getQuotation'
        ]),

        retrieveQuotation () {
            this.error = ''

            if (this.zipCode === '') {
                this.error = this.$t('zipCodeIsEmpty')

                return
            }

            this.getQuotation({
                token: this.$route.params.token,
                zipCode: this.zipCode
            }).catch(response => {
                this.error = this.$t('yourQuotationCouldNotBeFoundPleaseContactYourAdvisor')
            })
        }
    }
}
</script>

<style scoped>

</style>
