import Vue from 'vue'
import VueI18n from 'vue-i18n'
import nlNL from '@/i18n/locale/nl_NL'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'nl',
    messages: {
        nl: nlNL
    }
})

export default i18n
