<template>
    <div class="flex-col lg:mr-10 w-full lg:w-1/5 flex-grow-1" :class="{ 'mx-2': !displayQuotation }">
        <QuotationDownloadInformation v-if="!displayQuotation" />
        <QuotationSignedInformation v-if="isAccepted" />
        <ConfirmButton v-else @click="toggleModal" v-bind="$attrs" />
<!--        <DownloadButton v-bind="$attrs" />-->
        <LeafletList />
    </div>
</template>

<script>
import ConfirmButton from '@/components/ConfirmButton'
// import DownloadButton from '@/components/DownloadButton'
import LeafletList from '@/components/LeafletList'
import QuotationSignedInformation from '@/components/quotationSignedInformation'
import { mapGetters } from 'vuex'
import QuotationDownloadInformation from '@/components/quotationDownloadInformation'

export default {
    components: {
        QuotationDownloadInformation,
        QuotationSignedInformation,
        ConfirmButton,
        // DownloadButton,
        LeafletList
    },

    methods: {
        toggleModal () {
            this.$emit('toggleModal')
        }
    },

    computed: {
        ...mapGetters('quotation', [
            'signature'
        ]),

        ...mapGetters('app', [
            'displayQuotation'
        ]),

        isAccepted () {
            return this.signature !== null
        }
    }
}
</script>

<style>

</style>
