import axios from 'axios'

const api = axios.create({
    baseURL:
        process.env.VUE_APP_BASE_PROTOCOL +
        process.env.VUE_APP_BASE_URL +
        '/api/v1',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Company': process.env.VUE_APP_COMPANY,
        'X-Api-Token': process.env.VUE_APP_API_TOKEN
    }
})

export default api
