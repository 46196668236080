<template>
    <div class="flex flex-col py-4 border border-gray-400 bg-white rounded-lg border content-center items-center mb-10">
        <p v-show="!isAccepted" class="text-xl pb-2 font-bold" style="color: #006787">{{ $t('forApproval') }}</p>
        <button v-show="isAccepted" class="disabled cursor-text justify-center w-3/4 rounded-full border border-transparent shadow-sm py-4 bg-green-600 text-base font-medium text-white sm:text-sm">
            {{ $t('signed') }}
        </button>
        <button v-show="!isAccepted" @click="$emit('click')" class="justify-center w-3/4 rounded-full border border-transparent shadow-sm py-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
            {{ $t('sign' )}}
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('quotation', [
            'signature'
        ]),

        isAccepted () {
            return this.signature !== null
        }
    }
}
</script>

<style>

</style>
