<template>
    <div>
        <div class="flex flex-col py-4 border border-gray-400 bg-white rounded-lg border items-center mb-10">
            <p class="text-xl pb-2 font-bold text-center" style="color: #006787">{{ $t('downloadYourQuotation') }}</p>
            <button
                @click="download"
                :disabled="loading"
                type="button"
                class="justify-center w-3/4 rounded-full border border-transparent shadow-sm py-4 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                :class="loading ? 'cursor-not-allowed bg-gray-300' : 'hover:bg-green-700 bg-green-600 text-white'"
            >
                <div class="mx-auto items-center justify-center w-3/6 rounded-full">
                    <span v-show="loading">
                        <i class="fas fa-spin fa-spinner"></i>
                    </span>
                    <span v-show="!loading">
                        <i class="fas fa-download"></i>
                    </span>
                    {{ $t('download') }}
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import Quotation from '@/apis/helios/quotation/quotation'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            loading: false
        }
    },

    computed: {
        ...mapGetters('quotation', [
            'zipCode',
            'fileName'
        ])
    },

    methods: {
        download () {
            if (this.loading) {
                return
            }

            this.loading = true

            Quotation.download(this.$route.params.token, this.zipCode).then(({ data }) => {
                const fileURL = window.URL.createObjectURL(new Blob([data]))
                const fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', this.fileName)
                document.body.appendChild(fileLink)

                fileLink.click()
            })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style scoped>

</style>
