<template>
    <div class="flex-col pt-4 bg-white rounded-lg border border-gray-200 text-left pb-3">
        <p class="text-xl font-bold text-center pb-2" style="color: #006787">{{ $t('leaflets') }}</p>
        <div v-for="(category, index) in categories" :key="'category-' + index" class="py-2 pl-4">
            <p class="font-semibold" style="color: #006787">{{ category.title }}</p>
            <ul>
                <li v-for="brochure in category.brochures" :key="'category-' + index + '-' + brochure.id" class="cursor-pointer">
                    <a @click="download(brochure)">
                        <div class="flex">
                            <svg viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path style="fill: #98c131" d="M12.172 12L9.343 9.172l1.414-1.415L15 12l-4.243 4.243-1.414-1.415z"/></svg>
                            <span style="max-width: 90%">
                                {{ brochure.title }}
                            </span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: function () {
        return {}
    },

    computed: {
        ...mapGetters('quotation', [
            'editions',
            'brochures'
        ]),

        categories () {
            const categories = []

            if (this.brochures.length > 0) {
                categories.push({
                    title: this.$t('general'),
                    brochures: this.brochures
                })
            }

            this.editions.forEach((edition) => {
                if (edition.brochures.length > 0) {
                    categories.push({
                        title: this.$t('quotation') + ' ' + edition.name,
                        brochures: edition.brochures
                    })
                }
            })

            return categories
        }
    },

    methods: {
        download (brochure) {
            window.open(brochure.url)
        }
    }
}
</script>

<style scoped>

</style>
