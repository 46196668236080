import api from '@/apis/helios/api'

export default {
    async get (token, zipCode) {
        return api.post(`/quotation/${token}`, {
            zip_code: zipCode
        })
    },

    async accept (token, form) {
        return api.post(`/quotation/${token}/accept`, form)
    },

    async download (token, zipCode) {
        return api.post(`/quotation/${token}/download`, { zip_code: zipCode }, { responseType: 'blob' })
    }
}
