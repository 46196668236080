import Quotation from '@/apis/helios/quotation/quotation'

export const getQuotation = ({ commit }, { token, zipCode }) => {
    return new Promise((resolve, reject) => {
        Quotation.get(token, zipCode).then(({ data }) => {
            commit('SET_QUOTATION', data)
            commit('SET_ZIP_CODE', zipCode)

            resolve()
        }).catch((errors) => {
            reject(errors)
        })
    })
}

export const acceptQuotation = ({ commit, state }, { token, signature, editionId }) => {
    return new Promise((resolve, reject) => {
        Quotation.accept(token, {
            zip_code: state.zipCode,
            signature: signature,
            edition_id: editionId
        }).then(({ data }) => {
            commit('SET_QUOTATION', data)

            resolve()
        }).catch((errors) => {
            reject(errors)
        })
    })
}
