<template>
    <div class="fixed z-10 inset-0 overflow-y-auto bg-white bg-opacity-50">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0"></div>
            </div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>

            <div class="inline-block  border border-gray-400 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-600 sm:mx-0 sm:h-10 sm:w-10">
                            <svg viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 2.992v18.016a1 1 0 0 1-.993.992H3.993A.993.993 0 0 1 3 21.008V2.992A1 1 0 0 1 3.993 2h16.014c.548 0 .993.444.993.992zM19 4H5v16h14V4zm-7.707 9.121l4.243-4.242 1.414 1.414-5.657 5.657-3.89-3.89 1.415-1.414 2.475 2.475z"/></svg>
                        </div>

                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                {{ $t('signQuotation') }}
                            </h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-800">{{ $t('chooseTheQuotationYouWantToConfirmBelow') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex row px-6 pb-4 space-x-4">
                    <EditionAcceptCard
                        v-for="edition in editions"
                        :key="edition.id"
                        :edition="edition"
                        :editionSelected="(editionId == edition.id)"
                        @selectEdition="editionId = edition.id"
                    />
                </div>
                <div v-show="editionId != null" class="flex 25vh 10vw px-6 pb-6 space-x-4">
                    <div class="flex-column" style="width: 100%">
                        <label>{{ $t('signature' )}}<span class="text-red-500">*</span></label>
                        <VueSignaturePad
                            class="border border-grey-500"
                            :options="{ onBegin: () => {$refs.signaturePad.resizeCanvas()} }"
                            :width="signaturePadWidth"
                            :height="signaturePadHeight"
                            ref="signaturePad"
                        />
                    </div>
                </div>
                <div class="flex-row">
                    <div class="flex w-full justify-center">
                        <span class="pl-6 w-full text-start" v-bind:class="(error != null) ? 'text-red-500' : 'text-green-800'">{{error}} {{message}}</span>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse">
                        <button type="button" :disabled="loading" @click="toggleModal" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {{ $t('back') }}
                        </button>
                        <button
                            v-show="editionId != null"
                            :disabled="loading"
                            type="submit"
                            @click="click"
                            class="w-full inline-flex justify-center rounded-md border border-transparent bg-green-600 shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                            :class="loading ? 'cursor-not-allowed' : 'hover:bg-green-700'"
                        >
                            {{ $t('sign') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span class="top-1/2 my-0 mx-auto block relative w-0 h-0 text-3xl" style="top: 50%;">
                {{ $t('processing') }}...
            </span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
import { mapActions, mapGetters } from 'vuex'
import EditionAcceptCard from '@/components/partials/editionAcceptCard'

Vue.use(VueSignaturePad)

export default {
    components: {
        EditionAcceptCard
    },

    data () {
        return {
            editionId: null,
            error: null,
            message: null,
            loading: false
        }
    },

    computed: {
        ...mapGetters('quotation', [
            'editions'
        ]),

        ...mapGetters('app', [
            'displayQuotation'
        ]),

        signaturePadWidth () {
            if (!this.displayQuotation) {
                return '100%'
            }

            return '462px'
        },

        signaturePadHeight () {
            if (!this.displayQuotation) {
                return '150px'
            }

            return '210px'
        }
    },

    methods: {
        ...mapActions('quotation', [
            'acceptQuotation'
        ]),

        toggleModal () {
            this.restoreOutput()
            this.$emit('toggleModal')
        },
        click () {
            if (this.editionId != null) {
                this.save()
                this.$emit('acceptQuotation')
            } else this.error = 'Selecteer een offerte om verder te gaan.'
        },
        save () {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

            if (isEmpty) {
                this.error = this.$t('signatureIsRequiredToSignTheQuotation')

                return
            }

            this.loading = true

            this.acceptQuotation({
                token: this.$route.params.token,
                signature: data,
                editionId: this.editionId
            }).then(() => {
                this.toggleModal()

                this.$swal({
                    title: this.$t('quotationIsSigned'),
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: this.$t('close')
                })
            }).catch(() => {
                this.error = this.$t('somethingWentWrongPleaseContactYourAdviser')
            }).finally(() => {
                this.loading = false
            })
        },
        restoreOutput () {
            this.editionId = null
            this.error = null
            this.message = null
            this.$refs.signaturePad.clearSignature()
        }
    }
}
</script>

<style scoped>
</style>
