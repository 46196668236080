import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
    require.context('@/router/middleware', false, /.*\.js$/)
)

const routes = [
    {
        path: '/:token',
        name: 'quotation',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/quotation/view'),
        meta: {
            title: 'Offerte | ' + process.env.VUE_APP_COMPANY_NAME
        }
    },
    {
        path: '*',
        beforeEnter: () => {
            window.location.replace('https://energiewonen.nl')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(beforeEach)

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach (to, from, next) {
    const middleware = ['documentTitle']

    // Call each middleware.
    callMiddleware(middleware, to, from, (...args) => {
        next(...args)
    })
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware (middleware, to, from, next) {
    const stack = middleware.reverse()

    const _next = (...args) => {
        // Stop if "_next" was called with an argument or the stack is empty.
        if (args.length > 0 || stack.length === 0) {
            return next(...args)
        }

        const middleware = stack.pop()

        if (typeof middleware === 'function') {
            middleware(to, from, _next)
        } else if (routeMiddleware[middleware]) {
            routeMiddleware[middleware](to, from, _next)
        } else {
            throw Error(`Undefined middleware [${middleware}]`)
        }
    }

    _next()
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware (requireContext) {
    return requireContext
        .keys()
        .map(file => [
            file.replace(/(^.\/)|(\.js$)/g, ''),
            requireContext(file)
        ])
        .reduce(
            (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
            {}
        )
}

export default router
