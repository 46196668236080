<template>
    <div class="w-full md:w-3/5 lg:w-full xl:w-3/4 lg:px-4" v-if="hasQuotation">
        <div class="flex pb-3" :class="{ 'px-2': !displayQuotation }">
            <div class="flex-col lg:mr-10 w-1/5 flex-grow-1" v-if="displayQuotation"></div>
            <div class="w-full lg:w-4/5 flex flex-col py-4 border border-gray-400 bg-white rounded-lg border content-center items-start pl-6">
                <p class="text-xl font-semibold" style="color: #006787">{{ $t('quotationFor', { name: leadName}) }}</p>
            </div>
        </div>
        <div class="flex">
            <SideBar @toggleModal="toggleModal" :is-accepted="isAccepted" />
            <div class="w-4/5" v-if="displayQuotation">
                <slot />
            </div>
            <CardModal v-show="!isClosed" @toggleModal="toggleModal" @acceptQuotation="acceptQuotation" />
        </div>
    </div>
    <div v-else>
        <ZipCodeCheck />
    </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import CardModal from '@/components/CardModal'
import { mapGetters } from 'vuex'
import ZipCodeCheck from '@/components/quotation/zipCodeCheck'

export default {
    components: {
        SideBar,
        CardModal,
        ZipCodeCheck
    },

    data () {
        return {
            isClosed: true,
            isAccepted: false
        }
    },

    computed: {
        ...mapGetters('app', [
            'displayQuotation'
        ]),

        ...mapGetters('quotation', [
            'quotation',
            'lead'
        ]),

        hasQuotation () {
            return this.quotation !== null
        },

        leadName () {
            if (!this.hasQuotation) {
                return ''
            }

            return this.lead.full_name
        }
    },

    methods: {
        toggleModal () {
            this.isClosed = !this.isClosed
        },
        acceptQuotation () {
            this.isAccepted = true
        }
    }
}
</script>

<style>
.swal2-modal {
    font-family: sans-serif !important;
}
</style>
