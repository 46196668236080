<template>
    <div
        class="w-full md:w-1/2 border rounded-lg cursor-pointer px-4 py-2 shadow-sm font-medium"
        v-bind:class="editionSelected ? 'bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-white' : 'border-gray-300 bg-gray-200 text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'"
        v-on:click="() => $emit('selectEdition')"
    >
        <div class="">
            <div v-if="editionSelected" class="float-right">
                <svg class="fill-current text-white" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 2.992v18.016a1 1 0 0 1-.993.992H3.993A.993.993 0 0 1 3 21.008V2.992A1 1 0 0 1 3.993 2h16.014c.548 0 .993.444.993.992zM19 4H5v16h14V4zm-7.707 9.121l4.243-4.242 1.414 1.414-5.657 5.657-3.89-3.89 1.415-1.414 2.475 2.475z"/></svg>
            </div>
            <h3>{{ $t('quotation') }} {{ edition.name }}</h3>
        </div>
        <span class="text-xs" v-for="(panel, index) in edition.products.panel" :key="'panel' +  index">
                            {{ panel.amount }}x {{ panel.name }} <br />
                        </span>
        <span class="text-xs" v-for="(inverter, index) in edition.products.inverter" :key="'inverter' +  index">
                            {{ inverter.name }} <br />
                        </span>
        &euro; {{ edition.price.total }}
    </div>
</template>

<script>
export default {
    props: {
        edition: {
            type: Object,
            required: true
        },
        editionSelected: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
