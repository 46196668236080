import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './assets/css/tailwind.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@fortawesome/fontawesome-pro/css/all.css'
import '@fortawesome/fontawesome-pro/js/all.js'

Vue.config.productionTip = false

Vue.use(VueSweetalert2, {
    confirmButtonColor: '#48bb78',
    cancelButtonColor: '#f56565'
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
