<template>
    <div id="app" class="flex justify-center py-10">
        <Layout>
            <router-view />
        </Layout>
    </div>
</template>

<script>
import Layout from '@/components/layout'
import { mapActions } from 'vuex'

export default {
    components: {
        Layout
    },

    methods: {
        ...mapActions('app', [
            'setWindowWidth'
        ]),

        handleWindowChange () {
            this.setWindowWidth(window.innerWidth)
        }
    },

    created () {
        window.addEventListener('resize', this.handleWindowChange)
        this.handleWindowChange()
    }
}
</script>

<style>
body {
    background-image: url('./../src/assets/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
