export default {
    yourQuotation: 'Uw offerte',
    fillInYourZipCodeToViewTheQuotation: 'Voer uw postcode in om toegang te krijgen tot uw offerte.',
    zipCode: 'Postcode',
    retrieveQuotation: 'Offerte ophalen',
    yourQuotationCouldNotBeFoundPleaseContactYourAdvisor: 'Uw offerte is niet gevonden. Neem contact op met uw adviseur',
    zipCodeIsEmpty: 'U heeft nog geen postcode ingevuld.',
    quotationFor: 'Offerte voor {name}',
    sign: 'Onderteken',
    signed: 'Ondertekend',
    forApproval: 'Voor akkoord',
    downloadPdf: 'Downloaden (.PDF)',
    general: 'Algemeen',
    quotation: 'Offerte',
    chooseTheQuotationYouWantToConfirmBelow: 'Kies hieronder de offerte die u wilt accepteren en onderteken de offerte met uw handtekening.',
    signQuotation: 'Onderteken offerte',
    back: 'Terug',
    signature: 'Handtekening',
    leaflets: 'Brochures',
    signatureIsRequiredToSignTheQuotation: 'Een handtekening is vereist om de offerte te kunnen ondertekenen!',
    quotationIsSigned: 'De offerte is ondertekend!',
    close: 'Sluiten',
    youreQuotationIsSigned: 'Uw offerte is ondertekend',
    congratulationYoreQuotationIsSigned: 'Gefeliciteerd! Uw offerte is ondertekend.',
    processing: 'Verwerken',
    downloadYourQuotation: 'Download uw offerte',
    download: 'Download',
    somethingWentWrongPleaseContactYourAdviser: 'Er is iets mis gegaan. Neem contact op met uw adviseur.'
}
