<template>
    <div>
        <div class="flex flex-col py-4 border border-gray-400 bg-white rounded-lg border text-left mb-10">
            <p class="text-xl pb-2 font-bold text-center" style="color: #006787">{{ $t('congratulationYoreQuotationIsSigned') }}</p>
            <div class="mx-4 space-y-4">
                <p>
                    Welkom als klant van EnergieWonen!
                </p>
                <p>
                    U ontvangt ter bevestiging van ons een e-mail met het getekende contract als bijlage.
                </p>
                <p>
                    Dit is de eerste stap in het plaatsen van zonnepanelen op uw dak.
                    Wij zullen het contract verwerken. Binnen enkele dagen ontvangt u van ons informatie over de vervolgstappen.
                </p>
                <p>
                    De brochures van de producten zijn via deze pagina te downloaden.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
