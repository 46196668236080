export const quotation = (state) => {
    return state.quotation
}

export const lead = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'lead')) {
        return null
    }

    return state.quotation.lead
}

export const adviser = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'adviser')) {
        return null
    }

    return state.quotation.adviser
}

export const editions = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'editions')) {
        return []
    }

    return state.quotation.editions
}

export const editionCount = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'editions')) {
        return 0
    }

    return state.quotation.editions.length
}

export const signature = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'signature')) {
        return null
    }

    return state.quotation.signature
}

export const brochures = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'brochures')) {
        return null
    }

    return state.quotation.brochures
}

export const roofInformation = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'roof_information')) {
        return null
    }

    return state.quotation.roof_information
}

export const generalInformation = (state) => {
    if (!state.quotation || !Object.prototype.hasOwnProperty.call(state.quotation, 'general_information')) {
        return null
    }

    return state.quotation.general_information
}

export const zipCode = (state) => {
    return state.zipCode
}

export const fileName = (state) => {
    if (!state.quotation ||
        !Object.prototype.hasOwnProperty.call(state.quotation, 'file_name') ||
        state.quotation.file_name === ''
    ) {
        return 'Aanbieding.pdf'
    }

    return state.quotation.file_name
}
